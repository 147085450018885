import { useMutation, useQuery } from "react-query";
import GoogleBigQueryAPI from "../../services/repository/data-connector/destinations/google-big-query";
import MySQLAPI from "../../services/repository/data-connector/destinations/mysql";
import PostgresAPI from "../../services/repository/data-connector/destinations/postgres";
import RedshiftAPI from "../../services/repository/data-connector/destinations/redshift";
import GoogleBigQueryDestination, { PostGoogleBigQuery } from "../../services/models/http/data-connector/destination/google-big-query";
import Pagination from "../../services/models/http/paginated";
import DestinationAPI from "../../services/repository/data-connector/destination";
import MySQLDestination, { PostMySQL } from "../../services/models/http/data-connector/destination/mysql";
import PostgresDestination , { PostPostgres } from "../../services/models/http/data-connector/destination/postgres";
import { PostRedshift } from "../../services/models/http/data-connector/destination/redshift";



export const usePostDestinationMutation = () => useMutation('destination', (data: PostGoogleBigQuery|PostMySQL|PostPostgres|PostRedshift)  => {
  if(data.type === "GOOGLE_BIG_QUERY"){
    return GoogleBigQueryAPI._postOne(data);
  }else if(data.type === "MYSQL"){
    return MySQLAPI._postOne(data);
  }else if(data.type === "POSTGRES"){
    return PostgresAPI._postOne(data);
  }else if(data.type === "REDSHIFT"){
    return RedshiftAPI._postOne(data);
  }
  throw new Error(`Unable to write data for ${data.type} destination.`)
});

export const usePutDestinationMutation = (id: number) => useMutation(['destination', id], (data: PostGoogleBigQuery|PostMySQL|PostPostgres|PostRedshift)  => {
  if(data.type === "GOOGLE_BIG_QUERY"){
    return GoogleBigQueryAPI._put(id, data);
  }else if(data.type === "MYSQL"){
    return MySQLAPI._put(id, data);
  }else if(data.type === "POSTGRES"){
    return PostgresAPI._put(id, data);
  }else if(data.type === "REDSHIFT"){
    return RedshiftAPI._put(id, data);
  }
  throw new Error(`Unable to write data for ${data.type} destination.`)
});

export const useDeleteDestinationMutation = (id: number) => useMutation(['destination', id], ()  => {
  return DestinationAPI._delete(id);
});

export const useDestination = (id: number) => useQuery(['destination', id], () : Promise<GoogleBigQueryDestination|MySQLDestination> => DestinationAPI._getOne(id));
export const useDestinationListing = (page: number, options = {}) => useQuery(['destinations', page, options], () : Promise<Pagination<GoogleBigQueryDestination|MySQLDestination|PostgresDestination>> => DestinationAPI._getAll({pagination: false, query : {page, ...options}}));
