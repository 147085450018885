import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import MySQLDestination from "../../../models/http/data-connector/destination/mysql";

class MySQLAPI extends API{
  serializer = () => ({
    _postOne : MySQLDestination,
    _put: MySQLDestination
  })
}

export default Proxy<MySQLAPI>(new MySQLAPI('/destinations/mysql'));
