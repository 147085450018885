import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import { has, pickBy } from "lodash";
import Button from "../../../../ui/components/button/button";
import NetworkProvider from "../../../../config/network/network-provider";
import DataStreamModel from "../../../../services/models/http/data-connector/data-stream/data-stream";
import { useGetField } from "../../../../hooks/api";
import { NETWORK } from "../../../../constant/network";
import FieldAPI from "../../../../services/repository/google-datastudio/field";
import DataStreamJobAPI from "../../../../services/repository/data-connector/data-stream/data-stream-job";
import Modal, { DefaultBottom, Title } from "../../../../ui/components/modal";
import {
  DataStreamJob as HTTPDataStreamJob
} from "../../../../services/models/http/data-connector/data-stream/data-stream-job";

type OptionFieldType = {
  value: string,
  label: string,
  type: string
}
export type DataStreamJob = {
  fields: {
    [p: string]: string
  },
  datastreamJobDestinationOption: {
    partitionFieldId?: string | null
  }
};
export const Validation = Yup.object().shape({
  fields: Yup.object().required("You need to set at least one fields.")
});

type Props = {
  onSubmit: (data: Partial<DataStreamJob>) => void,
  dataStream: DataStreamModel,
  currentValue: {
    authorizations: Array<{
      authorizationId: number,
      id: string,
    }>,
  },
  defaultValue?: Partial<HTTPDataStreamJob>,
  saving?: boolean
}
const StepThree = ({ saving, onSubmit, dataStream, currentValue, defaultValue }: Props) => {
  const { t } = useTranslation();
  const [checking, setChecking] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [opened, setOpened] = useState(false);
  const [date, setDate] = useState<Date | null>(null);
  const [dataPreview, setDataPreview] = useState([]);
  const onClose = () => setOpened(false);

  const fields: ReturnType<typeof useGetField> | null = useGetField(
    dataStream.getPlatform() as NETWORK
  );


  const optionsFields = useMemo(() => {
    if (!fields.data) {
      return [];
    }
    return fields.data?.data
      ?.filter(elem => !elem.getFormula()).map((metric) => ({
        value: metric.getFieldID(),
        label: metric.getName(),
        type: metric.getFieldType()
      }));
  }, [fields.data]);


  const initialValues = {
    fields: {},
    datastreamJobDestinationOption: {
      partitionFieldId: undefined
    }
  };

  const formik = useFormik<DataStreamJob>({
    initialValues: defaultValue ? { ...initialValues, ...pickBy(defaultValue, (value, key) => Object.keys(initialValues).includes(key)) } : initialValues,
    validationSchema: Validation,
    onSubmit: async (values) => {
      setChecking(true);
      setError(null);
      try {
        await FieldAPI.checkFieldValidation(currentValue.authorizations[0].authorizationId as number, currentValue.authorizations[0].id as string, Object.keys(values.fields));
        const newValue = { ...values };
        if (newValue.datastreamJobDestinationOption.partitionFieldId === undefined) {
          newValue.datastreamJobDestinationOption.partitionFieldId = null;
        }
        onSubmit(newValue);
      } catch (e) {
        // @ts-ignore
        setError(e.message);
      }
      setChecking(false);
    }
  });

  const makePreviewRequest = async () => {
    setPreviewing(true);
    setError(null);
    setOpened(false);
    setDataPreview([]);
    try {
      const offset = date?.getTimezoneOffset() as number;
      const newDate = new Date(date?.getTime() as number - (offset * 60 * 1000));
      const data = await DataStreamJobAPI.preview(
        currentValue.authorizations[0].authorizationId as number,
        currentValue.authorizations[0].id as string,
        dataStream.getDestination().id,
        formik.values.fields,
        [],
        newDate.toISOString().split("T")[0],
        newDate.toISOString().split("T")[0]
      );

      setDataPreview(data.data);

      if (data.data.length === 0) {
        setError(`We do not have any data for the selected date. In order to proceed to the next step, you need to select a date for which you have some data.`);
      }
    } catch (e) {
      // @ts-ignore
      setError(e.response.data.message || e.message);
    } finally {
      setPreviewing(false);
    }
  };

  const tooltip: any = {};
  if (dataPreview.length === 0) {
    tooltip["data-tip"] = "You need to preview your data before checking.";
  }

  useEffect(() => {
    setDataPreview([]);
  }, [formik.values.fields]);

  return (
    <div>
      <Modal open={opened} onClose={onClose}>
        <Title title={t("Select a date to preview your data")} />
        <div className="pb-4">
          <DatePicker
            selected={date}
            onSelect={(dateNew) => setDate(dateNew)}
            onChange={(dateNew: Date | null) => setDate(dateNew)}
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            renderCustomHeader={({
                                   date,
                                   decreaseMonth,
                                   increaseMonth,
                                   prevMonthButtonDisabled,
                                   nextMonthButtonDisabled
                                 }) => (
              <div className="flex items-center justify-between px-2 py-2">
                                  <span className="text-lg text-gray-700">
                                      {format(date, "MMMM yyyy")}
                                  </span>
                <div className="space-x-2">
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    type="button"
                    className={`${prevMonthButtonDisabled && "cursor-not-allowed opacity-50"} inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500`}
                  >
                    <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                  </button>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    type="button"
                    className={`${nextMonthButtonDisabled && "cursor-not-allowed opacity-50"}inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500`}
                  >
                    <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                  </button>
                </div>
              </div>
            )}
          />
        </div>
        <div className="flex justify-end">
          <DefaultBottom disabledOkButton={date === null} onClose={onClose} titleButtonClose={t("Cancel")}
                         onOk={() => makePreviewRequest()} titleButtonOk={t("Preview")} />
        </div>
      </Modal>
      <div className="space-y-6">
        <form onSubmit={formik.handleSubmit}>
          <div className="bg-white  shadow sm:rounded-md sm:overflow-hidden divide-y divide-y-2">
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3
                  className="text-lg leading-6 font-medium text-gray-900">{t("Put the fields you want to extract.")}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t("You could always add more fields later.")}
                </p>
              </div>
            </div>
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <label className="block text-sm font-medium text-gray-700">
                Select the fields for
                your <b>{`${NetworkProvider.getByPlatform(dataStream.getPlatform()).getName()}`}</b> data stream
              </label>
              <Select
                isLoading={fields.isLoading}
                isMulti
                isClearable
                menuPortalTarget={document.body}
                value={Object.keys(formik.values.fields).map(field => optionsFields?.find(elem => elem.value === field)).filter((elem): elem is OptionFieldType => !!elem)}
                onChange={(newValue, actionMeta) => {
                  formik.setFieldValue(
                    "fields",
                    {
                      ...newValue.reduce((acc, elem) => ({
                        ...acc,
                        [elem.value]: has(formik.values.fields, elem.value) ? formik.values.fields[elem.value] : elem.value
                      }), {})
                    }
                  );
                }}
                name="fields"
                options={optionsFields}
                classNamePrefix="react-select"
              />
              <div>
                <fieldset className="border border-dashed border-gray-300 px-3 pb-3">
                  <legend className="px-2">{t("Rename your field")}</legend>
                  <div
                    className="mt-1 text-sm text-gray-500">{t("This is an optional feature. You could let the default settings if you want.")}</div>
                  <div className="mt-2 space-y-2">
                    {Object.keys(formik.values.fields).map((field) => <div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                          {optionsFields.find((elem) => elem.value === field)?.label}
                        </label>
                        <div className="mt-2 flex items-center gap-x-2">
                          <input
                            disabled
                            type="text"
                            name={`${field}_original`}
                            id={`${field}_original`}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="you@example.com"
                            value={field}
                          />
                          <input
                            type="text"
                            name={`${field}_new`}
                            id={`${field}_new`}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue={field}
                            value={formik.values.fields[field]}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "fields",
                                {
                                  ...formik.values.fields,
                                  [field]: e.target.value
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>)}
                  </div>
                </fieldset>
              </div>
              <div>
                <fieldset className="border border-dashed border-gray-300 px-3 pb-3">
                  <legend className="px-2">{t("Partition by data")}</legend>
                  <div
                    className="my-1 text-sm text-gray-500">{t("You can select a field here to enable always unique up to date data.")}</div>
                  {dataStream.getDestination().type === "GOOGLE_BIG_QUERY" ? <Select
                    isLoading={fields.isLoading}
                    menuPortalTarget={document.body}
                    isClearable
                    value={formik.values.datastreamJobDestinationOption.partitionFieldId ? optionsFields?.filter((elem) => elem.value === formik.values.datastreamJobDestinationOption.partitionFieldId) : undefined}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "datastreamJobDestinationOption.partitionFieldId", newValue?.value
                      );
                      if (newValue !== null) {
                        formik.setFieldValue(
                          "fields",
                          {
                            ...formik.values.fields, [newValue.value]: newValue.value
                          });
                      }
                    }}
                    name="datastreamJobDestinationOption.partitionFieldId"
                    options={optionsFields?.filter((elem) => elem.type === "YEAR_MONTH_DAY")}
                    classNamePrefix="react-select"
                  /> : null}
                  {dataStream.getDestination().type === "MYSQL" || dataStream.getDestination().type === "POSTGRES" || dataStream.getDestination().type === "REDSHIFT" ? <Select
                    isLoading={fields.isLoading}
                    menuPortalTarget={document.body}
                    isClearable
                    isMulti
                    value={formik.values.datastreamJobDestinationOption.partitionFieldId ? optionsFields?.filter((elem) => formik.values.datastreamJobDestinationOption.partitionFieldId?.split(",").includes(elem.value)) : undefined}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "datastreamJobDestinationOption.partitionFieldId", newValue.map((elem) => elem.value).join(",")
                      );
                      if (Array.isArray(newValue)) {
                        formik.setFieldValue(
                          "fields",
                          {
                            ...newValue.reduce((acc, elem) => ({
                              ...acc,
                              [elem.value]: has(formik.values.fields, elem.value) ? formik.values.fields[elem.value] : elem.value
                            }), {}),
                            ...formik.values.fields
                          });
                      }
                    }}
                    name="datastreamJobDestinationOption.partitionFieldId"
                    options={optionsFields}
                    classNamePrefix="react-select"
                  /> : null}
                </fieldset>
              </div>
            </div>
            {
              dataPreview.length > 0 ? <div className="py-4">
                <div>
                  <h2 className=" px-4 text-2xl font-bold text-gray-900">{t("Data Preview")}</h2>
                  <p className=" px-4 text-sm font-light text-gray-500">You only view the first 10 rows</p>
                </div>
                <div className="sm:-mx-6 lg:-mx-8">
                  <div style={{
                    "display": "grid",
                    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
                    "overflow": "auto",
                    whiteSpace: "nowrap"
                  }}
                       className="whitespace-nowrap block overflow-x-scroll py-2 align-middle sm:px-6 lg:px-8">
                    <table className="overflow-x-scroll divide-y divide-gray-300">
                      <thead>
                      <tr>
                        {
                          Object.keys(dataPreview[0]).map((key) => (
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              {key}
                            </th>))
                        }
                      </tr>
                      </thead>
                      <tbody className="bg-white">
                      {dataPreview.map((datum, i) => (
                        <tr key={i} className="even:bg-gray-50">
                          {Object.keys(datum).map((key) => (
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{datum[key]}</td>
                          ))}
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> : null
            }
            <div className="py-6 px-4 flex justify-end items-center">
              {
                error ? <div className="text-red-500 mr-4">
                  {error}
                </div> : null
              }
              <div className="space-x-2">
                <Button loading={previewing} type="grey" onClick={() => setOpened(true)}>
                  {t("Preview your data")}
                </Button>
                <Button {...tooltip} disabled={dataPreview.length === 0} loading={checking || saving} type="primary"
                        htmlType="submit">
                  {t("Save")}
                </Button>
              </div>
            </div>
          </div>
        </form>

      </div>
      <ReactTooltip place="top" type="dark" effect="float" />
    </div>
  );
};
export default StepThree;
