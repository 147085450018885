import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {stringify, parse} from 'query-string';
import { networkImageFromURI } from "../../../ui/components/network";
import {AMAZON_SELLER} from "../../../constant/network";
import Button from "../../../ui/components/button/button";
import {generatePath} from "../index";
import {checkConnectionOAuth2Status} from "../../../services/helper";
import { XCircleIcon } from "@heroicons/react/solid";
import SimpleSelect, {type ListValue} from "../../../ui/components/select/simple-select";
import * as qs from "query-string";

const validation = Yup.object({
    url: Yup.string().required()
})

const URLS: Array<ListValue> = [
    {name: "Canada", value: "https://sellercentral.amazon.ca"},
    {name: "US", value: "https://sellercentral.amazon.com"},
    {name: "Mexico", value: "https://sellercentral.amazon.com.mx"},
    {name: "Brazil", value: "https://sellercentral.amazon.com.br"},
    {name: "Spain", value: "https://sellercentral-europe.amazon.com"},
    {name: "UK", value: "https://sellercentral-europe.amazon.com"},
    {name: "France", value: "https://sellercentral-europe.amazon.com"},
    {name: "Netherlands", value: "https://sellercentral.amazon.nl"},
    {name: "Germany", value: "https://sellercentral-europe.amazon.com"},
    {name: "Italy", value: "https://sellercentral-europe.amazon.com"},
    {name: "Sweden", value: "https://sellercentral.amazon.se"},
    {name: "South Africa", value: "https://sellercentral.amazon.co.za"},
    {name: "Poland", value: "https://sellercentral.amazon.pl"},
    {name: "Egypt", value: "https://sellercentral.amazon.eg"},
    {name: "Turkey", value: "https://sellercentral.amazon.com.tr"},
    {name: "Saudi Arabia", value: "https://sellercentral.amazon.sa"},
    {name: "U.A.E.", value: "https://sellercentral.amazon.ae"},
    {name: "India", value: "https://sellercentral.amazon.in"},
    {name: "Belgium", value: "https://sellercentral.amazon.com.be"},
    {name: "Singapore", value: "https://sellercentral.amazon.sg"},
    {name: "Australia", value: "https://sellercentral.amazon.com.au"},
    {name: "Japan", value: "https://sellercentral.amazon.co.jp"},
];

const UrlByName = (name: string): string => {
    const url = URLS.find(item => item.name === name);
    if(url === undefined) {
        throw new Error(`The url is not defined for ${name} !`);
    }
    return url.value as string;
}

const AmazonSeller = () => {
    const query = parse(window.location.search)
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(true);
    const formik = useFormik({
        initialValues: {
            url: 'https://sellercentral.amazon.ca'
        },
        validationSchema: validation,
        async onSubmit(values){
            const baseUrl = UrlByName(values.url);
            const data = {
                ...values,
                url: baseUrl
            };
            setLoading(true);
            const returnValue = {
                options:{
                    url: data.url
                }
            }
            const response = await checkConnectionOAuth2Status("amazon-seller", returnValue);

            const search = qs.parse(window.location.search);
            if(response.getStatus() && search.redirectTo) {
                const url = new URL(search.redirectTo as string);
                window.location.replace(`${baseUrl}${url.pathname}${url.search}`);
            }else{
                setStatus(false);
            }

            setLoading(false);
        }
    })
    return (
        <div className="w-full h-full">
            <div className="bg-purple-600 h-16 text-white flex items-center justify-center">
                <h1 className="text-3xl font-bold text-white">Catchr x Amazon Seller</h1>
            </div>
            {!status ? <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">Unable to connect</h3>
                        <div className="mt-2 text-sm text-red-700">
                            Please review the information that you have submitted. We couldn't connect to your Amazon Seller account.
                        </div>
                    </div>
                </div>
            </div> : null}
            <div className="h-full flex flex-col  py-12 sm:px-6 lg:px-8">
                <div className="flex">
                    <div className="mr-4 flex-shrink-0">
                        <img src={networkImageFromURI(AMAZON_SELLER)}
                             className="h-16 w-16 bg-white text-gray-300" />
                    </div>
                    <div>
                        <h4 className="text-lg font-bold">{t("Connect to your Amazon Seller")}</h4>
                        <p className="mt-1">
                            {t("In order to connect to your Amazon Seller, we need to get some informations.")}
                        </p>
                    </div>
                </div>
                <form className="space-y-6 mt-4" onSubmit={formik.handleSubmit}>
                    <SimpleSelect
                        label="Marketplace"
                        id="url"
                        name="url"
                        onChange={formik.handleChange}
                        value={formik.values.url}
                        options={URLS.map(value => ({name: value.name, value: value.name}))}
                        placeholder="Select the marketplace you want to request"
                    />
                    <Button loading={loading} full size="large" type="primary" htmlType="submit">
                        Next
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default AmazonSeller;
