import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import RedshiftDestination from "../../../models/http/data-connector/destination/redshift";

class RedshiftAPI extends API{
  serializer = () => ({
    _postOne : RedshiftDestination,
    _put: RedshiftDestination
  })
}

export default Proxy<RedshiftAPI>(new RedshiftAPI('/destinations/redshift'));
