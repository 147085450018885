import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {useSegmentTrack} from "react-segment-analytics";
import DestinationForm, { Destination } from "../containers/destination-form";
import { useDestination, usePutDestinationMutation } from "../../../../hooks/destinations";
import Async from "../../../../ui/helper/async";
import { NotificationManager } from "../../../../ui/components/notification/notification";
import routing from "../../../../routing";
import GoogleBigQueryDestination from "../../../../services/models/http/data-connector/destination/google-big-query";
import MySQLDestination from "../../../../services/models/http/data-connector/destination/mysql";
import DestinationProvider from "../../../../config/destination/destination-provider";
import BaseDestination from "../../../../config/destination/base-destination";
import { GoogleBigQueryOptionsType } from "../options/google-big-query";
import { postFactory } from "../create";
import { MySQLOptionsType } from "../options/mysql";

const factory = (data: GoogleBigQueryDestination|MySQLDestination) : Destination => {

  const FirstDestination = DestinationProvider.getByPlatform(data.getType()) as BaseDestination;

  const options = {
    ...data.data,
    type: data.getType(),
  }
  return {
    name: data.getName(),
    destination: FirstDestination,
    options : options as unknown as GoogleBigQueryOptionsType|MySQLOptionsType
  }
}
const EditDestination = () => {
  const {t} = useTranslation();
  const {id} = useParams();

  const [submitting, setSubmitting] = useState(false);

  const destination = useDestination(id as never as number);
  const mutate = usePutDestinationMutation(id as never as number);

  const navigate = useNavigate();
  const track = useSegmentTrack();
  const onSubmit = async (data: Destination) => {
    setSubmitting(true);
    track("Trying to save a destination", {destination_id: id});
    try {
      await mutate.mutateAsync(postFactory(data));
      NotificationManager.success(t('Your destination has been edit.'), t('You can now use this destination in connection tab.'));
      navigate(routing.user.destination);
    }catch (e){
      NotificationManager.error(t('Unable to edit the destination.'), t('An error occurred when we try to edit your destination.'));
    }finally {
      setSubmitting(false);
    }
  };

  console.log(destination.data);

  return (
    <div className="w-full py-4 flex justify-center">
      <Async {...destination}>
        {destination.data ? <DestinationForm mode="EDIT" initialValue={factory(destination.data as GoogleBigQueryDestination|MySQLDestination)} onSubmit={onSubmit} submitting={submitting}/> : <div>No data</div>}
      </Async>
    </div>
  );
};

export default EditDestination;
