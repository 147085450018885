import React, {SelectHTMLAttributes} from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

export type ListValue = { name: string, value: string|undefined };
type SimpleSelectType = {
    label: string | Element,
    id: string,
    name: string,
    options: Array<ListValue>,
    error?: string | undefined,
    touched?: boolean | undefined
} & SelectHTMLAttributes<HTMLSelectElement>;

const SimpleSelect = ({
                id,
                name,
                label,
                error,
                touched,
                options,
                ...props
               }: SimpleSelectType) => {
    const enhancedProps: {
        "aria-invalid"?: string,
        "aria-describedby"?: string
    } = {};

    if (error) {
        enhancedProps["aria-invalid"] = "true";
        enhancedProps["aria-describedby"] = `${id}-error`;
    }
    return <div>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
            {label}
        </label>
        <div className={`mt-1 ${error ? "relative rounded-md shadow-sm" : null}`}>
            <select
                name={name}
                id={id}
                {...props}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            >
                {
                    options.map((value) => <option value={value.value}>{value.name}</option>)
                }
            </select>
        </div>
    </div>;
}

export default SimpleSelect;