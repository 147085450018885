import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import DestinationAPI from "../../../../services/repository/data-connector/destination";
import { CREATE, EDIT } from "../../../../constant/crud";
import Input from "../../../../ui/components/input/input";
import DestinationProvider from "../../../../config/destination/destination-provider";
import DestinationSelector from "../../../../ui/components/select/destination";
import GoogleBigQueryOptions, { GoogleBigQueryOptionsType, Validation as GoogleBigQueryValidation } from "../options/google-big-query";
import Button from "../../../../ui/components/button/button";
import { GOOGLE_BIG_QUERY, MYSQL, POSTGRES, REDSHIFT } from "../../../../constant/destination";
import MySQLOptions, { MySQLOptionsType, Validation as MysqlValidation } from "../options/mysql";
import MySQL from "../../../../config/destination/mysql";
import GoogleBigQuery from "../../../../config/destination/google-big-query";
import Postgres from "../../../../config/destination/postgres";
import PostgresOptions, {PostgresOptionsType, Validation as PostgresValidation} from "../options/postgres";
import RedshiftOptions, {RedshiftOptionsType, Validation as RedshiftValidation} from "../options/redshift";
import Redshift from "../../../../config/destination/redshift";

export type Destination = {
  name: string,
  destination: GoogleBigQuery|MySQL|Postgres|Redshift,
  options: GoogleBigQueryOptionsType|MySQLOptionsType|PostgresOptionsType|RedshiftOptionsType
};

export const Validation = Yup.object().shape({
  name:  Yup.string().required('You need to set a name to the connection.'),
  destination:  Yup.mixed().required('You need to set a destination to the connection.'),
  options: Yup.lazy((item : any) => {
    if(item.type === GOOGLE_BIG_QUERY){
      return GoogleBigQueryValidation;
    }
    else if(item.type === MYSQL){
      return MysqlValidation;
    }
    else if(item.type === POSTGRES){
      return PostgresValidation;
    }
    else if(item.type === REDSHIFT){
      return RedshiftValidation;
    }
    return Yup.mixed();
  })
});


const OptionsFactory = ({type, formik}: {type: string, formik: any}) => {
  switch (type){
    case "GOOGLE_BIG_QUERY":
      return <GoogleBigQueryOptions formik={formik} />
    case "MYSQL":
      return <MySQLOptions formik={formik} />
    case "POSTGRES":
      return <PostgresOptions formik={formik} />
    case "REDSHIFT":
      return <RedshiftOptions formik={formik} />
    default:
      throw new Error(`Unable to get the type ${type}`);
  }
}

const DestinationForm = ({ mode, onSubmit, submitting, initialValue }: { initialValue?:Destination,  submitting: boolean, mode: CREATE | EDIT, onSubmit: (data: Destination) => void }) => {
  const { t } = useTranslation();
  const FirstDestination = DestinationProvider.getAllConfigurable()[0];
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState(null);
  const initialValues = {name: "",
    destination: FirstDestination,
    options: {
      type: "GOOGLE_BIG_QUERY" as const,
      projectId: '',
      dataSetId: null,
      jsonKey: ''
    }, ...initialValue};


  const formik = useFormik<Destination>({
    initialValues,
    validationSchema: Validation,
    onSubmit: async (values) => {
      setValidating(true);
      setError(null);
      try {
        const result = await DestinationAPI.checkConnection(values.options.type, values.options);
        if(result.data.status === true){
          onSubmit(values);
        }else{
          setError(result.data.message);
        }
      }catch (e){
        // @ts-ignore
        setError(e.message);
      }
      setValidating(false);
    }
  });

  useEffect(() => {
    if(formik.values.destination instanceof MySQL){
      formik.resetForm({
        values: {
          destination: formik.values.destination,
          name: formik.values.name,
          options: {
            type: "MYSQL",
            host: (initialValues.options as MySQLOptionsType).host ?? "",
            username: (initialValues.options as MySQLOptionsType).username ?? "",
            password: (initialValues.options as MySQLOptionsType).password ?? "",
            database: (initialValues.options as MySQLOptionsType).database ?? ""
          }
        }
      })
    }else if(formik.values.destination instanceof Postgres){
      formik.resetForm({
        values: {
          destination: formik.values.destination,
          name: formik.values.name,
          options: {
            type: "POSTGRES",
            host: (initialValues.options as MySQLOptionsType).host ?? "",
            username: (initialValues.options as MySQLOptionsType).username ?? "",
            password: (initialValues.options as MySQLOptionsType).password ?? "",
            database: (initialValues.options as MySQLOptionsType).database ?? ""
          }
        }
      })
    }else if(formik.values.destination instanceof Redshift){
      formik.resetForm({
        values: {
          destination: formik.values.destination,
          name: formik.values.name,
          options: {
            type: "REDSHIFT",
            host: (initialValues.options as MySQLOptionsType).host ?? "",
            username: (initialValues.options as MySQLOptionsType).username ?? "",
            password: (initialValues.options as MySQLOptionsType).password ?? "",
            database: (initialValues.options as MySQLOptionsType).database ?? ""
          }
        }
      })
    }else {
      formik.resetForm({
        values: {
          destination: formik.values.destination,
          name: formik.values.name,
          options: {
            type: "GOOGLE_BIG_QUERY",
            projectId: (initialValues.options as GoogleBigQueryOptionsType).projectId ?? "",
            jsonKey: (initialValues.options as GoogleBigQueryOptionsType).jsonKey ?? "",
            dataSetId: (initialValues.options as GoogleBigQueryOptionsType).dataSetId ?? "",
          }
        }
      })
    }
  },[formik.values.destination]);

  return (
    <div className="space-y-6">
      <form onSubmit={formik.handleSubmit}>
        <div className="bg-white  shadow sm:rounded-md sm:overflow-hidden divide-y divide-y-2">
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3
                className="text-lg leading-6 font-medium text-gray-900">{mode === "CREATE" ? t("Create a new destination") : t("Update your destination")}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {t("If you want to export data to Google Data Studio or Google Sheets, you do not need any destination. Otherwise, just follow the step.")}
              </p>
            </div>
          </div>
          <div className="py-6 px-4 space-y-6 sm:p-6">
            <div>
              <Input
                id="name"
                name="name"
                label={t("Name :")}
                type="text"
                error={formik.errors.name}
                value={formik.values.name}
                touched={formik.touched.name}
                onChange={formik.handleChange}
                description={t("Set a name for the destination.")}
              />
            </div>
            <div>
              <DestinationSelector
                value={formik.values.destination}
                onChange={(destination) => formik.setFieldValue("destination", destination)}
                label={t("Destination :")}
              />
            </div>
          </div>
          <div>
            <OptionsFactory type={formik.values.options.type} formik={formik} />
          </div>
          <div className="py-6 px-4 flex justify-end items-center">
            {
              error ? <div className="text-red-500 mr-4">
                  {error}
            </div> : null
            }
            <div>
              <Button loading={submitting || validating} type="primary" htmlType="submit">
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default DestinationForm;
