import { JSONSchemaType } from "ajv/dist/types/json-schema";
import DestinationModel, { Destination, PostDestination } from "../destination";

export interface MySQL extends Destination {
  host: string,
  username: string,
  password: string,
  database: string
}

export interface PostMySQL extends PostDestination {

  host: string,
  username: string,
  password: string,
  database: string
}


class MySQLDestination extends DestinationModel<MySQL, PostMySQL> {

  getHost() {
    return this.data.host;
  }

  getUsername() {
    return this.data.username;
  }

  getPassword() {
    return this.data.password;
  }

  getDatabase() {
    return this.data.database;
  }


  getSchemaToSerialize(): JSONSchemaType<MySQL> {
    const schema = this.baseSchemaToSerialize();
    schema.properties = { ...schema.properties,
      "host": {
          type: ["string"]
        },
        "username": {
          type: ["string"]
        },
        "password": {
          type: ["string"]
        },
        "database": {
          type: ["string"]
        }};

    return schema as JSONSchemaType<MySQL>;
  }

  getSchemaPostToValidate(): JSONSchemaType<PostDestination & PostMySQL> {
    const schema = this.baseGetSchemaPostToValidate();
    schema.properties = { ...schema.properties,
      "host": {
        type: ["string"]
      },
      "username": {
        type: ["string"]
      },
      "password": {
        type: ["string"]
      },
      "database": {
        type: ["string"]
      }};

    return schema as JSONSchemaType<PostDestination & PostMySQL>;
  }

}

export default MySQLDestination;
