import BaseDestination from "./base-destination";
import { destinationImageUri } from "../../ui/components/destination";
import { Destination } from "../../constant/destination";

class Postgres extends BaseDestination{
  destinationImage(): string {
    return destinationImageUri(this.getDestinationIdentifier());
  }

  getDestinationIdentifier = (): Destination => "POSTGRES";

  getName = (): string => "Postgres";

}

export default Postgres;
