import { JSONSchemaType } from "ajv/dist/types/json-schema";
import DestinationModel, { Destination, PostDestination } from "../destination";

export interface Postgres extends Destination {
  host: string,
  username: string,
  password: string,
  database: string
}

export interface PostPostgres extends PostDestination {

  host: string,
  username: string,
  password: string,
  database: string
}


class PostgresDestination extends DestinationModel<Postgres, PostPostgres> {

  getHost() {
    return this.data.host;
  }

  getUsername() {
    return this.data.username;
  }

  getPassword() {
    return this.data.password;
  }

  getDatabase() {
    return this.data.database;
  }


  getSchemaToSerialize(): JSONSchemaType<Postgres> {
    const schema = this.baseSchemaToSerialize();
    schema.properties = { ...schema.properties,
      "host": {
        type: ["string"]
      },
      "username": {
        type: ["string"]
      },
      "password": {
        type: ["string"]
      },
      "database": {
        type: ["string"]
      }};

    return schema as JSONSchemaType<Postgres>;
  }

  getSchemaPostToValidate(): JSONSchemaType<PostDestination & PostPostgres> {
    const schema = this.baseGetSchemaPostToValidate();
    schema.properties = { ...schema.properties,
      "host": {
        type: ["string"]
      },
      "username": {
        type: ["string"]
      },
      "password": {
        type: ["string"]
      },
      "database": {
        type: ["string"]
      }};

    return schema as JSONSchemaType<PostDestination & PostPostgres>;
  }

}

export default PostgresDestination;
