import API from "../../../api/http/client";
import Proxy from "../../../api/http/proxy";
import PostgresDestination from "../../../models/http/data-connector/destination/postgres";

class PostgresAPI extends API{
  serializer = () => ({
    _postOne : PostgresDestination,
    _put: PostgresDestination
  })
}

export default Proxy<PostgresAPI>(new PostgresAPI('/destinations/postgres'));
